import {CREATE_PARTICIPANT_SESSION} from '../constants'
import multipleAttempts from '../common/lib/multipleAttempts'

const castIdToString = val => val && String(val)

export const attemptHistory = apiResponse => {
  if (!apiResponse.attempt_history) {
    return []
  }

  const mapped = apiResponse.attempt_history.map(attempt => {
    return {
      id: castIdToString(attempt.id),
      authoritative: attempt.authoritative,
      displayName: attempt.display_name,
      percentage: attempt.percentage,
      quizSessionId: castIdToString(attempt.quiz_api_quiz_session_id),
      bolaComplete: attempt.bola_complete,
    }
  })

  return mapped
}

export const participantSession = apiResponse => {
  return {
    anonymousGrading: apiResponse.participant_session.anonymous_grading,
    attemptHistory: attemptHistory(apiResponse),
    averageScore: apiResponse.average_score,
    completedAttemptCount: apiResponse.completed_attempt_count,
    canSeeResultsPage: apiResponse.participant_session.can_see_results_page,
    canSeeResultsItems: apiResponse.participant_session.can_see_results_items,
    host: apiResponse.host,
    lastSessionSubmittedAt: apiResponse.participant_session.submitted_at,
    lockAt: apiResponse.participant_session.lock_at,
    multipleAttempts: multipleAttempts(apiResponse.participant_session.multiple_attempts),
    participantId: castIdToString(apiResponse.participant_session.participant_id),
    quizSessionId: castIdToString(apiResponse.quiz_api_quiz_session_id),
    status: apiResponse.participant_session.status,
    timezone: apiResponse.participant_session.timezone,
    token: apiResponse.token,
    unlockAt: apiResponse.participant_session.unlock_at,
    userId: castIdToString(apiResponse.participant_session.user_id),
    maxAttempts: apiResponse.max_attempts == 0 ? Infinity : apiResponse.max_attempts,
    viewPermissions: apiResponse.participant_session.view_permissions || {},
    scoreToKeepWrtMultipleAttempts:
      apiResponse.participant_session.score_to_keep_wrt_multiple_attempts,
  }
}

export const createParticipantSessionHandler = (scope, participantSessionId) => {
  return function handleCreateParticipantSessionResponse(data) {
    return {
      participantSessions: {
        [participantSessionId]: participantSession(data),
      },
      scope,
      type: CREATE_PARTICIPANT_SESSION,
    }
  }
}
