import {TOGGLE_SIDEBAR} from '../constants'

export default (state = {open: false}, action = {}) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {open: !state.open}
    default:
      return state
  }
}
