import React from 'react'
import t from 'format-message'
import {Link} from '@instructure/ui-link'

import store from '../store'
import {getContentExport} from '../actions/apiActions'
import {EXPORT_STATUS_COMPLETE, EXPORT_STATUS_EXPORTING, EXPORT_STATUS_FAILED} from '../constants'

const pollingExports = new Set()

export const exportStatus = status => {
  switch (status) {
    case 'scheduled':
    case 'exporting':
    case 'loading_quiz_api_json':
      return EXPORT_STATUS_EXPORTING
    case 'failed':
    case 'failed_to_export_quizzes':
      return EXPORT_STATUS_FAILED
    case 'completed':
      return EXPORT_STATUS_COMPLETE
  }
}

export const pollForExport = (
  exportId,
  addAlert,
  activeAssignmentId,
  queryForExportFn = queryForExport,
) => {
  if (!pollingExports.has(exportId)) {
    pollingExports.add(exportId)
    queryForExportFn(exportId, addAlert, activeAssignmentId)
  }
}

const queryForExport = (exportId, addAlert, activeAssignmentId) => {
  store.dispatch(getContentExport(exportId)).then(responseData => {
    if (exportStatus(responseData.json.status) === EXPORT_STATUS_COMPLETE) {
      pollingExports.delete(exportId)
      addAlert({
        message: t.rich('Export complete! View on the <0>exports</0> page', [
          ({children}) => (
            <Link href={`/exports/${activeAssignmentId}`} key="0">
              {children}
            </Link>
          ),
        ]),
        variant: 'success',
      })
    } else if (exportStatus(responseData.json.status) === EXPORT_STATUS_EXPORTING) {
      setTimeout(() => {
        queryForExport(exportId, addAlert, activeAssignmentId)
      }, 2000)
    } else if (exportStatus(responseData.json.status) === EXPORT_STATUS_FAILED) {
      pollingExports.delete(exportId)
      addAlert({
        message: t('Export failed'),
        variant: 'warning',
      })
    }
  })
}
