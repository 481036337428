import {CREATE_PARTICIPANT_SESSION} from '../constants'

export default (state = [], action = {}) => {
  switch (action.type) {
    case CREATE_PARTICIPANT_SESSION:
      return action.participantSessions[Object.keys(action.participantSessions)[0]].attemptHistory
    default:
      return state
  }
}
