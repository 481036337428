const speedGraderLink = context => {
  if (context?.contextType !== 'course') {
    return null
  }

  const {canvasHost, canvasLocalContextId, canvasAssignmentId} = context

  return `${canvasHost}/courses/${canvasLocalContextId}/gradebook/speed_grader?assignment_id=${canvasAssignmentId}`
}

export default speedGraderLink
