/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import {withStyle, jsx, Global} from '@instructure/emotion'

import generateStyle from './styles'
import generateComponentTheme from './theme'

@withStyle(generateStyle, generateComponentTheme)
export default class PageHeader extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isNewTopNavBar: PropTypes.bool,
    makeStyles: PropTypes.func,
    styles: PropTypes.object,
  }

  static defaultProps = {
    isNewTopNavBar: false,
  }

  componentDidMount() {
    this.props.makeStyles()
  }

  componentDidUpdate() {
    this.props.makeStyles()
  }

  render() {
    return (
      <header className="header" css={this.props.styles.header}>
        <Global styles={this.props.styles.globalStyles} />
        <div css={this.props.styles.headerContent}>{this.props.children}</div>
      </header>
    )
  }
}
