import {RENDER_SUBMIT_BUTTON, NAVIGATE_TO} from '../constants'

export default (state = {renderSubmitButton: false}, action = {}) => {
  switch (action.type) {
    case RENDER_SUBMIT_BUTTON:
      return {renderSubmitButton: true}
    case NAVIGATE_TO:
      return {renderSubmitButton: false}
    default:
      return state
  }
}
