/** @jsx jsx */
import {Component} from 'react'
import {withStyle, jsx} from '@instructure/emotion'
import PropTypes from 'prop-types'
import t from 'format-message'

import generateStyle from './styles'

@withStyle(generateStyle, null)
export default class SkipToContent extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    isNewTopNavBar: PropTypes.bool,
    makeStyles: PropTypes.func,
    styles: PropTypes.object,
  }

  static defaultProps = {
    isNewTopNavBar: false,
  }

  componentDidMount() {
    this.props.makeStyles()
  }

  componentDidUpdate() {
    this.props.makeStyles()
  }

  focusContent() {
    const mains = document.querySelectorAll('div[role="main"]')
    mains[mains.length - 1].focus()
  }

  render() {
    return (
      <a
        tabIndex="1"
        href="#"
        className="skipNavigationLink"
        css={this.props.styles.skipNavigationLink}
        onClick={this.focusContent}
      >
        {t('Skip To Quiz Content')}
      </a>
    )
  }
}
