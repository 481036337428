/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'

import ReturnButton from 'quiz-react-sdk/es/common/components/shared/return_button/ReturnButton'
import {withStyle, jsx} from '@instructure/emotion'
import t from 'format-message'

import RespondusCommandButton from '../RespondusCommandButton'
import generateStyle from './styles'
import generateComponentTheme from './theme'

@withStyle(generateStyle, generateComponentTheme)
export default class ReturnButtonContainer extends React.Component {
  static propTypes = {
    platform: PropTypes.string,
    returnToUrl: PropTypes.string,
    rldbLaunch: PropTypes.bool,
    rldbCanExit: PropTypes.bool,
    iceTopNavBarEnabled: PropTypes.bool,
    makeStyles: PropTypes.func,
    styles: PropTypes.object,
  }

  static defaultProps = {
    platform: void 0,
    returnToUrl: void 0,
    rldbLaunch: false,
    rldbCanExit: false,
    iceTopNavBarEnabled: false,
  }

  componentDidMount() {
    this.props.makeStyles()
  }

  componentDidUpdate() {
    this.props.makeStyles()
  }

  get isMobile() {
    return 'mobile ios android'.split(' ').includes(this.props.platform)
  }

  get isMissingReturnUrl() {
    return this.props.returnToUrl == 'false'
  }

  get hidesReturnButton() {
    return this.isMobile || this.isMissingReturnUrl
  }

  render() {
    const {rldbLaunch, rldbCanExit} = this.props

    if (rldbLaunch && rldbCanExit) {
      return (
        <div css={this.props.styles.returnButtonContainer}>
          <RespondusCommandButton
            buttonLabel={t('Exit')}
            command="exit"
            iceTopNavBarEnabled={this.props.iceTopNavBarEnabled}
          />
        </div>
      )
    } else if (this.hidesReturnButton || rldbLaunch) {
      return null
    }

    return (
      <div css={this.props.styles.returnButtonContainer}>
        <ReturnButton
          returnToUrl={this.props.returnToUrl}
          text={t('Return')}
          iceTopNavBarEnabled={this.props.iceTopNavBarEnabled}
        />
      </div>
    )
  }
}
