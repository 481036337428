import {
  PARTICIPANT_UPDATED,
  MODERATE_TRAY_CLOSED,
  OPEN_MODERATE_TRAY,
  UPDATE_EXTRA_ATTEMPTS_PER_QUIZ,
  CREATE_MODERATION_PARTICIPANTS,
} from '../constants'
import slice from '../util/slice'

const initialState = {
  extraAttemptsQuiz: 0,
  participantId: null,
  maxAttempts: 1,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE_MODERATION_PARTICIPANTS:
      return setMaxAttempts(state, action)

    case PARTICIPANT_UPDATED:
      return (() => {
        const {participantId} = state
        if (action.participantId !== participantId) {
          return state
        }

        return attemptSettings({
          ...state,
          ...action.participant,
          participantId: action.participantId,
        })
      })()

    case MODERATE_TRAY_CLOSED:
      return (() => {
        const maxAttempts = state.maxAttempts
        // persist maxAttempts
        // maxAttempts means assessment allowed attempts
        // this value is supposed to be same for all participants
        //   in an assessment
        return {
          ...state,
          ...initialState,
          maxAttempts,
        }
      })()

    case OPEN_MODERATE_TRAY:
      return (() => {
        const maxAttempts = state.maxAttempts
        // for OPEN_MODERATE_TRAY, action.participant comes
        // from ModerationTable, which has maxAttempts of assessment
        // attempts + quiz level attempt
        // so we shouldn't use that value. we want the value from
        // state
        return attemptSettings({
          ...state,
          ...action.participant,
          participantId: action.participant.id,
          maxAttempts,
        })
      })()

    case UPDATE_EXTRA_ATTEMPTS_PER_QUIZ:
      return (() => {
        const maxAttempts = state.maxAttempts
        // persist maxAttempts from state
        // maxAttempts from state is set by CREATE_MODERATION_PARTICIPANTS
        return {
          ...state,
          ...action.settings,
          maxAttempts,
        }
      })()

    default:
      return state
  }
}

function setMaxAttempts(state, action) {
  const maxAttempts = assessmentAttempts(action)

  if (maxAttempts) {
    return {
      ...state,
      maxAttempts,
    }
  }

  return state
}

function assessmentAttempts(action) {
  const participants = action.participants

  if (participants.length == 0) {
    return null
  }
  return participants
    .map(p => p.maxAttempts)
    .reduce((accumulator, maxAttempts) => {
      return maxAttempts || accumulator
    })
}

function attemptSettings(participant) {
  const settings = slice(participant, Object.keys(initialState))
  return {
    ...settings,
    ...giveExtraAttemptsSettings(settings),
  }
}

function giveExtraAttemptsSettings(settings) {
  const extraAttemptsQuiz = String(settings.extraAttemptsQuiz || 0)
  return {extraAttemptsQuiz}
}
