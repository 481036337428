import {TOKEN_REFRESH_WINDOW_MS, TOKEN_SYNC_REFRESH_WINDOW_MS} from '../constants'

export const needsRefresh = ({method = '', path = '', token}) => {
  if (path === '/oauth/token' && method === 'POST') {
    return false
  } // Already refreshing
  if (!token.refreshToken || typeof token.refreshToken !== 'string') {
    return false
  }
  return tokenExpiring(token)
}

const tokenExpiring = ({createdAt, expiresIn, refreshToken}, currentTime = Date.now()) => {
  const createdAtMilliseconds = createdAt * 1000
  const expiresInMilliseconds = expiresIn * 1000
  const expiresAt = createdAtMilliseconds + expiresInMilliseconds

  const expiring = currentTime + TOKEN_REFRESH_WINDOW_MS > expiresAt
  // Force a synchronous refresh when we are very close to the expiration in case of misaligned clocks, etc
  const expired = currentTime + TOKEN_SYNC_REFRESH_WINDOW_MS >= expiresAt
  return {expired, expiring}
}
