export default function slice(obj, keys) {
  const sliced = {}

  keys
    .filter(key => Object.prototype.hasOwnProperty.call(obj, key))
    .forEach(key => {
      sliced[key] = obj[key] // eslint-disable-line immutable/no-mutation
    })

  return sliced
}
