import {TOGGLE_MODAL} from '../constants'

export default (state = {modals: {}}, action = {}) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        modals: {[action.name]: action.status},
      }
    default:
      return state
  }
}
