import config from '../config'
import {launchParams} from '../launchParams'

export const findOrCreateQuizSession = (
  assignmentId,
  windowObj = window,
  getLaunchParams = launchParams,
) => {
  const {accessToken} = getLaunchParams(windowObj.location.search, windowObj.sessionStorage)

  // eslint-disable-next-line no-param-reassign, immutable/no-mutation
  windowObj.location = `/easy_auth/take_quiz?token=${accessToken}&assignment_id=${assignmentId}`
}
export const createNewQuiz = (windowObj = window, getLaunchParams = launchParams) => {
  const {accessToken} = getLaunchParams(windowObj.location.search, windowObj.sessionStorage)

  // eslint-disable-next-line no-param-reassign, immutable/no-mutation
  windowObj.location = `/easy_auth/new_quiz?token=${accessToken}`
}

export default function (windowObj = window, configuration = config) {
  if (configuration.inDevelopment()) {
    /* eslint-disable no-param-reassign, immutable/no-mutation */
    windowObj.findOrCreateQuizSession = findOrCreateQuizSession
    windowObj.createNewQuiz = createNewQuiz
    /* eslint-enable no-param-reassign, immutable/no-mutation */
  }
}
