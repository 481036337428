import {STORE_CONTENT_EXPORTS} from '../constants'

const contentExportData = apiResponse => {
  return {
    id: apiResponse.id,
    status: apiResponse.status,
    createdAt: apiResponse.created_at,
    contentExportUrl: apiResponse.content_export_url,
  }
}

export default (state = {}, action = {}) => {
  switch (action.type) {
    case STORE_CONTENT_EXPORTS:
      // Merge
      return Object.assign(
        {},
        state,
        ...action.contentExports.map(contentExport => ({
          [contentExport.id]: contentExportData(contentExport),
        })),
      )
    default:
      return state
  }
}
