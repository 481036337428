/* eslint immutable/no-mutation:0 */
const environment = {
  apiHost: process.env.API_HOST || 'api.quiz.docker',
  apiProtocol: process.env.API_PROTOCOL || 'http',
  authorizationMode: process.env.AUTHORIZATION_MODE,
  nodeEnv: process.env.NODE_ENV || 'development',
  rceHost: process.env.RCE_HOST || 'dvgcns7dz7zc.cloudfront.net/rce_service/versions',
  sentryDSN: process.env.PUBLIC_SENTRY_DSN,
  sentryRelease: process.env.GIT_SHA || 'none',
}

environment.apiRoot = `${environment.apiProtocol}://${environment.apiHost}`
environment.inDevelopment = () => environment.nodeEnv === 'development'

try {
  environment.lang = window.sessionStorage.getItem('lang') || undefined
} catch (e) {
  environment.lang = undefined
}

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  environment.adminAToken = process.env.ADMIN_A_TOKEN
  environment.adminBToken = process.env.ADMIN_B_TOKEN
  environment.adminCToken = process.env.ADMIN_C_TOKEN
  environment.adminDToken = process.env.ADMIN_D_TOKEN
  environment.adminEToken = process.env.ADMIN_E_TOKEN
  environment.teacherAToken = process.env.TEACHER_A_TOKEN
  environment.teacherBToken = process.env.TEACHER_B_TOKEN
  environment.teacherCToken = process.env.TEACHER_C_TOKEN
  environment.teacherDToken = process.env.TEACHER_D_TOKEN
  environment.teacherEToken = process.env.TEACHER_E_TOKEN
  environment.studentAToken = process.env.STUDENT_A_TOKEN
  environment.studentBToken = process.env.STUDENT_B_TOKEN
  environment.studentCToken = process.env.STUDENT_C_TOKEN
  environment.studentDToken = process.env.STUDENT_D_TOKEN
  environment.studentEToken = process.env.STUDENT_E_TOKEN

  environment.defaultContextId = process.env.DEFAULT_CONTEXT_ID
  window.ENV = process.env
}

export default environment
