import React from 'react'
import PropTypes from 'prop-types'
import {Button} from '@instructure/ui-buttons'

import SecondaryNavBarButton from '@instructure/quiz-core/es/common/components/layout/navbar/SecondaryNavBarButton'

export default class RespondusCommandButton extends React.Component {
  static propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    command: PropTypes.string.isRequired,
    iceTopNavBarEnabled: PropTypes.bool,
  }

  static defaultProps = {
    iceTopNavBarEnabled: false,
  }

  closeRespondusWindow = () => {
    window.parent.postMessage(this.props.command, '*')
  }

  render() {
    if (this.props.iceTopNavBarEnabled) {
      return (
        <SecondaryNavBarButton
          id="RespondusCommandButton"
          variant="button"
          onClick={this.closeRespondusWindow}
        >
          {this.props.buttonLabel}
        </SecondaryNavBarButton>
      )
    }

    return <Button onClick={this.closeRespondusWindow}>{this.props.buttonLabel}</Button>
  }
}
