import formatMessage from 'format-message'
import {setupFormatMessage as sdkSetupFormatMessage} from '@instructure/quiz-i18n/es/util/setupFormatMessage'
import moment from 'moment-timezone'

export default function () {
  return Promise.all([
    import(
      /* webpackChunkName: "translations/lti-[request]" */ `../translations/${window.locale}.json`
    ).catch(() => ({default: {}})),
    import(
      /* webpackChunkName: "translations/outcomes-[request]" */ `@instructure/outcomes-ui/translations/${window.locale}.json`
    ).catch(() => ({default: {}})),
    import(
      /* webpackChunkName: "translations/sdk-[request]" */ `@instructure/quiz-translations/lib/${window.locale}.json`
    ).catch(() => ({default: {}})),
    window.moment_locale
      ? import(
          /* webpackChunkName: "moment/locale/[request]" */ `moment/locale/${window.moment_locale}.js`
        )
      : null,
  ]).then(result => {
    if (window.moment_locale) {
      moment.locale(window.moment_locale)
    }
    // Required for the handful of components (e.g. loading spinner) that are used without a SDKApp wrapper
    sdkSetupFormatMessage(window.locale, {
      translations: result[2].default,
      logMissingTranslations: true,
    })
    formatMessage.setup({
      locale: window.locale, // what locale strings should be displayed
      generateId: require('format-message-generate-id/underscored_crc32'),
      translations: {
        [window.locale]: Object.assign({}, result[0].default, result[1].default),
      },
    })
    return {
      quizLti: result[0].default,
      outcomesSdk: result[1].default,
      quizSdk: result[2].default,
    }
  })
}
