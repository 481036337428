import {STORE_FEATURES} from '../constants'

export default (state = [], action = {}) => {
  switch (action.type) {
    case STORE_FEATURES:
      return action.features
    default:
      return state
  }
}
