import {v4 as uuid} from 'uuid'
import {ADD_ALERT, REMOVE_ALERT} from '../constants'

function addAlert({message, variant, removeIn = 5000, guid = uuid()}) {
  return {
    type: ADD_ALERT,
    payload: {
      message,
      removeIn,
      uuid: guid,
      variant,
    },
  }
}

export function screenreaderNotification(message) {
  return addAlert({message, variant: 'screenreader', removeIn: 2000})
}

export function removeAlert(uuid) {
  return {
    type: REMOVE_ALERT,
    payload: {uuid},
  }
}
