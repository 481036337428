import {SET_LAUNCH_TOKEN} from '../constants'

export default (state = {}, action = {}) => {
  switch (action.type) {
    case SET_LAUNCH_TOKEN:
      return {
        ...state,
        ...action.tokenInfo,
      }
    default:
      return state
  }
}
