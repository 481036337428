const castToBool = val => (val ? val === true || val === 'true' : false)

export default (input = {}) => {
  return {
    isAttemptLimited: castToBool(input.attempt_limit),
    coolingPeriod: castToBool(input.cooling_period),
    coolingPeriodSeconds: input.cooling_period_seconds,
    enabled: castToBool(input.multiple_attempts_enabled),
    maxAttempts: input.max_attempts,
    scoreToKeep: input.score_to_keep,
    buildOnLastAttempt: castToBool(input.build_on_last_attempt),
  }
}
