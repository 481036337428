import {
  PARTICIPANT_UPDATED,
  MODERATE_TRAY_CLOSED,
  OPEN_MODERATE_TRAY,
  UPDATE_REDUCE_CHOICES_PER_QUIZ,
} from '../constants'
import slice from '../util/slice'

const initialState = {
  reduceChoicesEnabledQuiz: false,
  participantId: null,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case PARTICIPANT_UPDATED:
      return (() => {
        const {participantId} = state
        if (action.participantId !== participantId) {
          return state
        }

        return answerChoiceSettings({
          ...state,
          ...action.participant,
          participantId: action.participantId,
        })
      })()

    case MODERATE_TRAY_CLOSED:
      return initialState

    case OPEN_MODERATE_TRAY:
      return answerChoiceSettings({
        ...state,
        ...action.participant,
        participantId: action.participant.id,
      })

    case UPDATE_REDUCE_CHOICES_PER_QUIZ:
      return {
        ...state,
        ...action.settings,
      }

    default:
      return state
  }
}

function answerChoiceSettings(participant) {
  const settings = slice(participant, Object.keys(initialState))
  return {
    ...settings,
    ...reduceAnswerChoicesSettings(settings),
  }
}

function reduceAnswerChoicesSettings(settings) {
  const reduceChoicesEnabledQuiz = Boolean(settings.reduceChoicesEnabledQuiz && true)
  return {reduceChoicesEnabledQuiz}
}
