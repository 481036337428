const generateStyle = (componentTheme, props) => {
  const msMaxWidth = 'max-width: 48em'
  const mq = `@media screen and (${msMaxWidth})`
  const mp = '@media print'
  return {
    header: {
      position: 'fixed',
      zIndex: '4444',
      top: '0',
      left: '0',
      right: '0',
      height: componentTheme.headerHeight,
      transition: 'padding 300ms ease',
      backgroundColor: componentTheme.headerBackgroundColor,
      borderBottom: componentTheme.headerBottomBorder,
      boxSizing: 'border-box',
      [mp]: {
        display: 'none',
      },
      ...(props.isNewTopNavBar && {
        [mq]: {
          height: '3.5rem',
        },
      }),
    },
    globalStyles: {
      'bodytox-fullscreen .header': {
        zIndex: '0',
      },
    },
    headerContent: {
      display: 'flex',
      lineHeight: '4rem',
      height: 'inherit',
      ...(props.isNewTopNavBar && {
        [mq]: {
          lineHeight: '3.5rem',
        },
      }),
    },
  }
}

export default generateStyle
