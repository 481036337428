import {LOAD_PREVIEW_DATA, CLEAR_PREVIEW_DATA} from '../constants'

export default (state = {}, action = {}) => {
  switch (action.type) {
    case LOAD_PREVIEW_DATA:
      return action.data
    case CLEAR_PREVIEW_DATA:
      return {}
    default:
      return state
  }
}
