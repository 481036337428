const generateStyle = componentTheme => {
  return {
    quizIcon: {
      fontSize: componentTheme.quizIconFontSize,
      padding: '0 1.5rem',
    },
  }
}

export default generateStyle
