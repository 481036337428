const generateStyle = (componentTheme, props) => {
  const msMaxWidth = 'max-width: 48em'
  const mq = `@media screen and (${msMaxWidth})`
  const mp = '@media print'
  return {
    page: {
      height: '100%',
    },
    withHeader: {
      boxSizing: 'border-box',
      paddingTop: componentTheme.headerHeight,
      height: 'inherit',
      [mp]: {
        paddingTop: '0',
      },
      ...(props.features.includes('ice_top_nav_bar') && {
        [mq]: {
          paddingTop: '3.5rem',
        },
      }),
    },
  }
}

export default generateStyle
