import {ADD_ALERT} from '../constants'
import {removeAlert} from '../actions/alerts'

const alertTimerMiddleware =
  store =>
  next =>
  (action, delay = setTimeout) => {
    if (action && action.type === ADD_ALERT && action.payload.removeIn) {
      const remove = () => {
        store.dispatch(removeAlert(action.payload.uuid))
      }

      // injected for testing
      delay(remove, action.payload.removeIn)
    }

    return next(action)
  }

export default alertTimerMiddleware
